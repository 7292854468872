import { themeGet } from '@styled-system/theme-get';
import styled from "styled-components"



export const BannerWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 40px;
  min-height: 802px;
  overflow: hidden;
  position: relative;
  background-image: -moz-linear-gradient(
    280deg,
    rgb(100, 43, 115) 0%,
    rgb(198, 66, 110) 100%
  );
  background-image: -webkit-linear-gradient(
    280deg,
    rgb(13, 125, 248) 15%,
    rgb(157, 69, 167) 50%,
    rgb(255, 31, 112) 85%
  );
  background-image: -ms-linear-gradient(
    280deg,
    rgb(13, 125, 248) 15%,
    rgb(157, 69, 167) 50%,
    rgb(255, 31, 112) 85%
  );
  @media only screen and (min-width: 1201px) and (max-width: 1440px) {
    min-height: 100vh;
    // padding: 20px 0 -50px;
  }
  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 90px;
  }
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(802px - 100px);
    @media only screen and (min-width: 1201px) and (max-width: 1440px) {
      min-height: calc(100px - 50px);
      margin-top: 35px;
      align-items: flex-start;
      justify-content: flex-start;
    }
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }
  .bannerBottomShape {
    position: absolute;
    right: 0;
    bottom: -2px;
    @media only screen and (max-width: 999px) {
      display: none;
    }
  }
`;

export const BannerContent = styled.div`
  width: 60%;
  flex-shrink: 0;
  @media only screen and (max-width: 1199px) {
    flex-shrink: 0;
    width: 100%;
    margin-top: 25px;
  }
  @media only screen and (min-width: 1366px) {
    width: 94%;
    line-height: 42px;
    margin-bottom: 20px;
    // padding-top: 20px;
  }


  h1 {
    font-size: 28px;
    line-height: 42px;
    font-weight: 700;
    color: ${themeGet('colors.menu', '#0D233E')};
    margin-bottom: 24px;r
    @media only screen and (min-width: 480px) and (max-width: 1366px) {
      font-size: 46px;
      // margin: 30px 0;
    }  
    @media only screen and (min-width: 1366px) {
      font-size: 60px;
      margin: 40px 0;
    }  
  }

  h3 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    color: ${themeGet('colors.menu', '#0D233E')};
    margin-bottom: 7px;
    @media only screen and (min-width: 480px) and (max-width: 1366px) {
      font-size: 28px;
      // margin: 30px 0;
    }  
    @media only screen and (min-width: 1366px) {
      font-size: 38px;
      margin: 90px 0 -20px;
    }  
  }


  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    // font-weight: 400;
    line-height: 29px;
    @media only screen and (min-width: 1366px) {
      width: 80%;
      font-size: 28px;
      line-height: 36px;
      margin-top: 60px;
    }
  }
`;


export const ComplideText = styled.p`
  p {
    margin: 0;
    font-weight: bold;
    // text-align: center;
    font-size: 24px;
    @media only screen and (max-width: 410px) {
      margin: 20px 0 10px;
      font-size: 19px;
    }
    @media only screen and (max-width: 650px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 991px) and (max-width: 1219px) {
      font-size: 18px;
      margin-bottom: 5px;
      margin-top: -15px;
    }
    @media only screen and (min-width: 1220px) {
      font-size: 18px;
      margin-bottom: 5px;
      margin-top: -5px;
    }
  }
`;

export const ComplideTextLink = styled.a`
  margin: 0;
  font-weight: normal;
  color: ${themeGet('colors.textColor', 'rgba(51, 51, 51)')};
  text-decoration: none;
  &:hover {
    font-weight: 500;
  text-decoration: underline;
  }
`;



export const SectionWrapper = styled.section`
  width: 100%;
  overflow: hidden;

  
  display: flex;
  align-items: center;
  justify-content: center;
  
`;

export const ParticipateBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
  padding: 36px 18px 0 18px;
  gap: 26px;
  @media only screen and (max-width: 410px) {
    gap: 30px;
  }
  @media only screen and (max-width: 650px) {
    gap: 34px;
  }
  @media only screen and (max-width: 800px) {
    width: 900px;
    max-width: 700px;
  }
  @media only screen and (max-width: 990px) {
    padding: 50px 18px 57px 18px;
    width: 920px;
    max-width: 920px;
  }
  @media only screen and (min-width: 1220px) {
    width: 1220px;
    max-width: 1170px;
    margin-bottom: 60px;
  }
`;

export const HowToParticipateHeading = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  @media only screen and (min-width: 410px) {
    font-size: 35px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 38px;
  }
  @media only screen and (min-width: 1220px) {
    text-align: left;
    font-size: 38px;
  }
`;

export const ParticipateCardBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;
  @media only screen and (min-width: 410px) {
    gap: 50px;
  }
  @media only screen and (min-width: 650px) {
    gap: 55px;
  }
  @media only screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 55px;
  }
`;

export const ParticipateCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  @media only screen and (min-width: 410px) {
    gap: 13px;
  }
  @media only screen and (min-width: 650px) {
    gap: 15px;
  }
  @media only screen and (min-width: 800px) {
    font-size: 26px;
  }
`;

export const ParticipateCardText = styled.p`
  margin: 0;
  color: black;
  text-align: center;
  font-size: 16px;
  @media only screen and (min-width: 410px) {
    font-size: 19px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 800px) {
    font-size: 18px;
  }
`;


export const MarinadeWrapper = styled.div`
  // padding-top: 50px;
  padding-bottom: 10px;
  min-height: 200px;
  overflow: hidden;
  position: relative;
  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 5px;
  }
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(200px - 100px);
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
    @media only screen and (max-width: 780px) {
      flex-wrap: wrap;
    }
  }
`;


export const HubbleWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 10px;
  min-height: 200px;
  overflow: hidden;
  position: relative;

  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 90px;
  }
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(200px - 100px);
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
    @media only screen and (max-width: 780px) {
      flex-wrap: wrap;
    }
  }
`;



export const LifinityWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 100px;
  margin-bottom: 20px;
  min-height: 200px;
  overflow: hidden;
  position: relative;

  @media only screen and (max-width: 1099px) {
    min-height: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 90px;
  }
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: calc(400px - 100px);
    @media only screen and (max-width: 1099px) {
      min-height: 100%;
    }
    @media only screen and (max-width: 780px) {
      flex-wrap: wrap;
    }
  }
`;

export const ImageWrapper = styled.div`
  margin-left: -30px;
  @media only screen and (max-width: 780px) {
    margin-left: -10px;
  }
 
`;



export const TextWrapper = styled.div`
  width: 428px;
  align-self: center;
  margin-top: -50px;
  @media only screen and (min-width: 1220px) {
    padding-left: 25px;
    width: 500px;
    margin-right: 40px;
    margin-top: -37px;
  }
  @media only screen and (max-width: 1220px) {
    padding-left: 40px;
  }
  @media only screen and (max-width: 991px) {
    width: 350px;
    padding-left: 40px;
    margin-right: -28px;
  }
  @media only screen and (max-width: 780px) {
    width: 100%;
    padding-left: 0;
    margin-top: 40px;
  }
  h3 {
    color: ${themeGet('colors.headingColor', '#0F2137')};
    font-size: 27px;
    line-height: 23px;
    font-weight: 600;
    margin-bottom: 20px;
    // @media only screen and (max-width: 990px) {
    //   font-size: 26px;
    //   line-height: 31px;
    //   margin-bottom: 15px;
    // }
    @media only screen and (min-width: 991px) and (max-width: 1219px) {
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 10px;
    }
    @media only screen and (min-width: 1220px) {
      font-size: 28px;
      line-height: 50px;
      margin-bottom: -9px;
      margin-top: 11px;
    }
  }
  p {
    color: ${themeGet('colors.textColor', 'rgba(51, 51, 51)')};
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    @media only screen and (min-width: 1220px) {
      font-size: 22px;
      width: 80%;
      line-height: 32px;
      margin-bottom: 15px;
      margin-top: 20px;
    }
    @media only screen and (min-width: 991px) and (max-width: 1219px) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 12px;
      margin-top: -14px;
    }
  }
  // > p {
  //   margin-bottom: 20px;
  // }
  button {
    margin-top: 20px
  }
  #text{
      // margin-top: -21px;
      // margin-bottom: -4px;
    @media only screen and (min-width: 1220px) {
      margin-top: -36px;
      margin-bottom: -16px;
    }
    @media only screen and (min-width: 991px) and (max-width: 1219px) {
        margin-bottom: 15px;
        margin-top: -38px;
    }

  }
  .alpha{
    @media only screen and (min-width: 1220px) {
      padding-left: 5px;
      width: 600px;
      margin-top: 46px;
      margin-bottom: 54px;
    }
    @media only screen and (min-width: 991px) and (max-width: 1219px) {
        padding-left: 5px;
        // width: 600px;
        margin-bottom: 15px;
        margin-top: 20px;
    }
    button {
      font-weight: 600;
      @media only screen and (min-width: 1220px) { 
        margin-top: 12px;
        margin-bottom: -24px;
      }
      @media only screen and (min-width: 991px) and (max-width: 1219px) { 
        margin-top: 11px;
        margin-bottom: -60px;
      }
    }
  }
  .scholar{
    @media only screen and (min-width: 1220px) {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding-left: 5px;
      width: 628px;
      line-height: 20px;
      margin-top: 60px;
      margin-bottom: 64px;
    }
    p{
      margin-top: 4px;
      margin-bottom: 6px;
    }
    //  > p{
    //   margin-top: 6px;
    //   margin-bottom: 50px;
    //  }
    @media only screen and (min-width: 991px) and (max-width: 1219px) {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 15px;
        margin-top: 20px;
    }
    button {
      font-weight: 600;
      @media only screen and (min-width: 1220px) { 
        width: 26%;
        margin-top: -2px;
        margin-bottom: -13px;
      }
      @media only screen and (min-width: 991px) and (max-width: 1219px) { 
        margin-top: 16px;
        margin-bottom: -18px;
      }
    }
  }
  .sals{
    @media only screen and (min-width: 1220px) {
      padding-left: 5px;
      width: 600px;
      margin-top: 50px;
      margin-bottom: 54px;
    }
    button {
      font-weight: 600;
      @media only screen and (min-width: 1220px) { 
        margin-top: 100px;
        margin-bottom: -15px;
      }
      @media only screen and (min-width: 991px) and (max-width: 1220px) { 
        margin-top: 6px;
        margin-bottom: -15px;
      }
    }
  }
  
`;