
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppModern/Navbar';
import Footer from 'containers/AppModern/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import Seo from 'components/seo';
import { HubbleSection, MarinadeSection, LifinitySection, HeadingSection, ScholarshipBanner } from '../containers/AppModern/Scholarship';
import Layout from 'components/layout';


const AppModern = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="SamoDAO" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <Layout>
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-active">
              <Navbar />
            </Sticky>
            <ContentWrapper>
              <ScholarshipBanner />
              <HeadingSection />
              {/* <ScholarshipSection /> */}
              <MarinadeSection />
              <HubbleSection />
              <LifinitySection />
            </ContentWrapper>
            <Footer />
          </AppWrapper>
        </Layout>
      </>
    </ThemeProvider>
  );
};
export default AppModern;

