
import { ComplideText, ComplideTextLink, HubbleWrapper, TextWrapper, SectionWrapper, MarindeWrapper, LifinityWrapper, BannerContent, BannerWrapper, HowToParticipateHeading, ParticipateBox, ParticipateCard, ParticipateCardBox, ParticipateCardText, ImageWrapper, MarinadeWrapper } from './style';
import React, { Fragment } from 'react';
import Fade from 'react-reveal/Fade';
import { openModal, closeModal } from '@redq/reuse-modal';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import { openUrl } from '../../../utils/misc';


import Image2 from 'common/assets/image/appModern/marinade.png';
// import Image2 from 'common/assets/image/appModern/scholarship.png';
import Image3 from 'common/assets/image/appModern/lifinity.png';
import Image4 from 'common/assets/image/appModern/hubble.png';


export const LifinitySection = () => ( 
     <LifinityWrapper>
      <Container>
        <ImageWrapper>
          <Fragment>
            <img 
              src={Image3} 
              height={400} 
              alt='Lifinity logo' 
            />
          </Fragment>
        </ImageWrapper>
        <TextWrapper > 
         <div className="sals">
            <Heading 
              as="h3" 
              content="Lifinity ($LFNTY & Lifinity Flares)" 
            />
            <Text 
              content="The first proactive market maker on #Solana designed to improve capital efficiency and reduce impermanent loss." 
            />
            <br></br>
            <ComplideText>
              Compiled by : <ComplideTextLink href='https://twitter.com/GoodSamoSamo' target='_blank'> @goodsamosamo</ComplideTextLink>.
            </ComplideText>
            <Button 
              title="Coming Soon" 
              onClick={() => openUrl('')}
            />
          </div>
        </TextWrapper>
      </Container>
    </LifinityWrapper>
);


  
export const HubbleSection = () => (
    <HubbleWrapper>
      <Container>
        <ImageWrapper>
          <Fragment>
            <img 
              src={Image4} 
              height={400} 
              alt="Hubble Image"  
            />
          </Fragment>
        </ImageWrapper>
        <TextWrapper>
          <div className="alpha">
            <Heading 
              as="h3" 
              content="Hubble Protocol ($HBB) & Kamino"
            />
            <Text 
              content="Kamino is a first-of-its-kind DeFi protocol that unifies Lending, Liquidity, and Leverage into a single, secure DeFi product suite." 
            />
            <br></br>
            <Text 
              id="text" 
              content="Hubble Protocol enables users to borrow USDH against multiple assets.Borrowing USDH allows users to access the liquidity  in their long-term holding tokens." 
            />
            <ComplideText>
              Compiled by : <ComplideTextLink href='https://twitter.com/bartfomoshi' target='_blank'> @bartfomoshi</ComplideTextLink>.
            </ComplideText>
            <Button 
              title="Coming Soon" 
              onClick={() => openUrl('')}
            />
          </div>
        </TextWrapper>
      </Container>
    </HubbleWrapper>
);
  

 



export const HeadingSection = () => (
  <SectionWrapper>
    <ParticipateBox>
      <HowToParticipateHeading>Read Samo DAO scholarship reports of 2023 </HowToParticipateHeading>
      <ParticipateCardBox> 
        <ParticipateCard>
          <ParticipateCardText>
            The best part? You don't need to be an investing pro or professional writer to do so... 
          </ParticipateCardText>
        </ParticipateCard>
      </ParticipateCardBox>
    </ParticipateBox>
  </SectionWrapper>
);





export const ScholarshipBanner = () => {

  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="SamoDAO Scholarships "
            />
          </Fade>
          <Fade up delay={200}>
            <Text
              content="SamoDAO scholarships are excellent opportunities for curious 
                individuals who are interested in researching a project
                alongside others, producing a report that they can call their
                own, go down in SamoDAO history, and get rewarded
                accordingly. "
            />
            <Heading
              as="h3"
              content="Who Should Participate? "
            />
            <Text
                content="
                  If you're passionate about diving deep into projects and/or
                  have a deep desire to better understand a project and
                  produce a piece of content that you can be proud of, then
                  SamoDAO scholarships are for you!"
            />
          </Fade>
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};




export const MarinadeSection = () => (
  <MarinadeWrapper>
    <Container>
      <ImageWrapper>
        <Fragment>
          <img 
            src={Image2} 
            height={400} 
            alt='Marinade Finance' 
          />
        </Fragment>
      </ImageWrapper>
      <TextWrapper>
        <div className="scholar">
          <Heading 
            as="h3" 
            content="Marinade Finance ($MNDE)" 
          />
          <Text 
            content="Marinade Finance has come to market to solve the aforementioned problems on the Solana Network and further accelerate the adoption of the Layer-1 blockchain." 
          />
          <br></br>
          <Text 
            id="text"
            content="The Marinade Platform enables users to stake Solana's native token (SOL) via multiple product offers to help users earn rewards and help further decentralize Solana." 
          />
          <br></br>
          <ComplideText> 
            Compiled by : <ComplideTextLink href='https://twitter.com/GoodSamoSamo' target='_blank'> @GoodSamoSamo</ComplideTextLink>, <ComplideTextLink href='https://twitter.com/@Quickscope40' target='_blank'>    @Quickscope40</ComplideTextLink>:  
          </ComplideText>
          <Button 
            title="Read Report" 
            onClick={() => openUrl('https://docsend.com/view/vsecsj2vf374ay8d')}
          />
        </div>
      </TextWrapper>
    </Container>
  </MarinadeWrapper>
);



